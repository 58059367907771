import { DeepKeyof, language } from "../../traductions";

const traduction = {
  it: {
    seo: {
      description:
        "Essere lofter significa curiosità, ambizione e passione. Lavoriamo per portare innovazione nel mondo del software e dei prodotti digitali. Ti va di unirti a noi?",
    },
    header: {
      headerTitle: "Pochi passi dal lavoro dei tuoi sogni",
      headerSub: "Almeno così ci hanno detto",
    },
    beLofters: {
      title: "Essere lofter significa curiosità, ambizione e passione",
      subtitle1:
        "Ogni giorno lavoriamo per portare innovazione e creare il mondo in cui vogliamo vivere.",
      subtitle2: "Ti va di unirti a noi?",
      buttonText: "POSIZIONI APERTE",
    },
    dreamersAndInnovators: {
      overtitle: "È il posto per te?",
      dreamers: "Sognatori ",
      innovators: "innovatori",
      title:
        ", chiamaci come preferisci: vogliamo costruire i prodotti digitali del futuro",
      card1Title: "Fiducia e rispetto reciproco",
      card1Descr:
        "Siamo un team ambizioso e vogliamo costruire, tanto! Sappiamo che anche quando avremo raggiunto la cima della montagna ne vedremo un’altra da scalare e di questo andiamo fieri.",
      card2Title: "Lifelong learner",
      card2Descr:
        "Vogliamo circondarci di persone talentuose, ma soprattutto rispettose le une delle altre, di cui ci si possa fidare ciecamente sotto tutti gli aspetti. Vogliamo esserci gli uni per gli altri.",
      card3Title: "Nessun micro-management",
      card3Descr:
        "Abbiamo scelto di gestire il lavoro di ognuno di noi evitando micro-management e persone dedicate esclusivamente alla gestione di task e consegne. ",
      card4Title: "Mossi dalla curiosità",
      card4Descr:
        "Teniamo sempre gli occhi aperti, cerchiamo la prossima miglioria, scaviamo nelle novità e in quello che è già presente nel mondo. Non si finisce mai di imparare!",
      card5Title: "Risolviamo quello che non va",
      card5Descr:
        "Come gli scout! Quando qualcosa non va ci mettiamo in prima linea per fare in modo che funzioni come dovrebbe.",
      card6Title: "Crediamo in quello che facciamo",
      card6Descr:
        "Consideriamo sempre il nostro tempo come un investimento: se lo investiamo nelle cose che riteniamo inutili che guadagno ne ricaviamo?",
    },
    onlyTheBest: {
      overtitle: "Ci piace essere coccolati",
      title: "Solo il meglio per chi vuole raggiungere il meglio",
      card1Title: "Formazione continua",
      card1Description:
        "Quello che facciamo oggi probabilmente sarà considerato vecchio in meno di due anni. Per questo ci impegniamo a formarci continuamente, dedicando anche giornate lavorative allo studio del nostro settore. ",
      card2Title: "Assicurazione sanitaria",
      card2Description:
        "Per tutti i dipendenti da almeno tre mesi in apprendistato è attiva un’assicurazione sanitaria con rimborsi percentuali o totali sulle spese per visite mediche, esami e analisi per se stessi e familiari a carico.",
      card3Title: "Cibo e bevande",
      card3Description:
        "Offriamo il pranzo ogni giorno, sia in versione onnivora che vegetariana. Oltre a questo, nella sala relax ci sono snack e bevande di ogni tipo, dal classico caffè, ai ginseng, alle granite!",
      card4Title: "Partecipazione ad eventi",
      card4Description:
        "Per noi è importante partecipare ad eventi di sviluppo e design - oltre ad organizzarne qualcuno - in modo da avere sempre possibilità di confrontarci con colleghi di altri mondi.",
      card5Title: "Lavoro ibrido",
      card5Description:
        "Ci piace passare parte del nostro tempo insieme, ma capiamo anche i lati positivi del lavoro remoto! Ognuno ha la possibilità di organizzarsi due giorni alla settimana da casa.",
      card6Title: "Biblioteca",
      card6Description:
        "Leggere apre e tiene allenata la mente, per questo ogni mese raccogliamo le richieste e facciamo un ordine di libri che vanno ad alimentare la biblioteca Mabiloft.",
      card7Title: "Gadget",
      card7Description:
        "Abbiamo una passione per tutto ciò che riguarda stampare cose. Ogni nuovo arrivato riceve un kit di tazza, quaderno, penne, t-shirt, sticker e qualsiasi altra cosa ci venga in mente",
      card8Title: "Postazione tutta tua",
      card8Description:
        'Una scrivania tutta tua, MacBook e monitor 27" 2k e tutto ciò di cui potresti avere bisogno, ci piace lavorare bene e comodamente.',
      card9Title: "L’ambiente per la tua crescita",
      card9Description:
        "Gli unici limiti alla tua crescita saranno quelli che ti porrai tu. Le tue idee verranno sempre ascoltate e valutate, indipendentemente dal tempo che hai passato con noi.",
    },
    openPositions: {
      title: "Posizioni aperte",
      subtitle: "Ti va di provare?",
      jobTitleMob: "Mobile developer",
      jobTitleBiz: "Business developer",
      jobTitleBra: "Brand manager",
      jobTitleDes: "Digital product designer",
      jobTitleDesJun: "Junior digital product designer",
      jobTitleBac: "Backend developer",
      jobTitle0: "Candidatura spontanea",
      jobDescrMob:
        "Le app negli store che funzionano male sono il tuo cruccio perenne? Anche il nostro, potremmo essere un ottimo team insieme.",
      jobDescrBiz:
        "Quello che ti fa svegliare la mattina è la voglia di far crescere un business e progettare strategie sui prossimi passi da fare? Stiamo cercando te!",
      jobDescrBra:
        "Trovare nuovi modi di comunicare è il tuo pane quotidiano? Potresti essere la persona giusta per diventare dei nostri!",
      jobDescrDes:
        "Se per te la cosa che più si avvicina alla felicità è una collezione di post-it colorati, potresti essere la persona che stiamo cercando.",
      jobDescrBac:
        "I database e le API sono la tua specialità? Non sopporti di dover attendere il caricamento di un'app? Allora questo è il lavoro perfetto per te!",
      jobDescr0:
        "Non sempre avremo posizioni aperte perfette per te, ma è dalle cose inaspettate che possono nascere le più grandi storie!",
      buttonText: "CANDIDATI",
      yearsOfExp: "anni di esperienza",
      noExp: "Nessuna esperienza richiesta",
    },
    opinionsCards: {
      person2: "Simone, Mobile Developer",
      person1: "Beatrice, Digital Product Designer",
      person3: "Matteo, Digital Product Designer Intern",
      person4: "Tiziano, CEO",
      person5: "Eddy, CTO",
      person6: "Daniele, Backend Developer",
      person7: "Martina, Junior Frontend Developer",
      descr2:
        "Mabiloft è una continua sfida, mi dà la spinta di cui ho bisogno per dare il meglio di me",
      descr1:
        "È un posto in cui crescere assieme, avendo a disposizione sempre tutto ciò di cui hai bisogno per poter stare al meglio.",
      descr3:
        "Mabiloft mi ha dato un bellissimo tirocinio, educativo, illuminante e istruttivo sia dal punto di vista professionale che, soprattutto, umano.",
      descr4:
        "Ogni giorno posso pormi nuove sfide e so che troverò qualcuno con cui lavorarci.",
      descr5:
        "È come essere in un catalizzatore, qui dentro riesco ad essere la versione migliore di me stesso",
      descr6:
        "Non a tutti capita la fortuna di poter lavorare in un posto così, dove la tua opinione conta, dove ti viene data la possibilità di perfezionarti ed essere riconosciuti nel farlo.",
      descr7:
        "Un posto in cui ognuno ha spazio per mostrare la propria identità ed è incoraggiato ad esprimersi",
    },
  },
  en: {
    seo: {
      description:
        "Being lofter means curiosity, ambition and passion. We work to innovate the world of software and technological products. Would you like to join us?",
    },
    header: {
      headerTitle: "A few steps from your dream job",
      headerSub: "At least they told us so",
    },
    beLofters: {
      title: "Being lofters means curiosity, ambition and passion",
      subtitle1:
        "Everyday we work hard to bring innovation and create the world we want to live in.",
      subtitle2: "Would you like to join us?",
      buttonText: "OPEN POSITIONS",
    },
    dreamersAndInnovators: {
      overtitle: "Is it the place for you?",
      dreamers: "Dreamers ",
      innovators: "innovators",
      title:
        ", call us as you wish: we want to build the digital products of the future",
      card1Title: "Trust and mutual respect",
      card1Descr:
        "We are an ambitious team and we want to create a lot! We know that even when we will reach the top, we will see new horizons and we are proud of this.",
      card2Title: "Lifelong learner",
      card2Descr:
        "We want to be surrounded by talented people, but most of all respectful of others, who we can trust in every way. We want to be there for each others.",
      card3Title: "No micro-management",
      card3Descr:
        "We chose to handle everyone's work avoiding micro-management and people entirely dedicated to task management and submissions.",
      card4Title: "Driven by curiosity",
      card4Descr:
        "We keep our eyes open, looking for the next upgrade, digging deep into the innovation and into what's already available in the world. You never stop learning!",
      card5Title: "We solve what is wrong",
      card5Descr:
        "As boyscouts! When something is wrong we are on the front line to make it work as it should.",
      card6Title: "We believe in what we do",
      card6Descr:
        "We consider our time as an investment: what would we get from wasting time in things we consider unuseful?",
    },
    onlyTheBest: {
      overtitle: "We like to be pampered",
      title: "Only the best for those who want to reach the top",
      card1Title: "Lifelong learning",
      card1Description:
        "What we are building today will probably be obsolete in less than two years. That's why we engage in continuing education, spending even days studying our field. ",
      card2Title: "Health insurance",
      card2Description:
        "All the employees who have been apprentices for at least three months are covered by health insurance with refunds of a percentage or the total amount of medical bills, exams and testing for themselves and dependent family members.",
      card3Title: "Food and beverages",
      card3Description:
        "We offer lunch every day, both omnivore and vegetarian. Beside, in the break room there are snacks and beverages of all sorts, from coffee to ginseng and even slushes!",
      card4Title: "Event attendance",
      card4Description:
        "We value participation to coding and design events - in addition to host some  - so as to have the opportunity to interact with colleagues in other industries.",
      card5Title: "Hybrid work",
      card5Description:
        "We love to spend time together, but we also understand the advantages of remote working! Everyone has the possibility to work from home two days per week.",
      card6Title: "Library",
      card6Description:
        "Reading broadens and trains the mind, that's why every month we collect requests and order books which grow the library of Mabiloft.",
      card7Title: "Gadget",
      card7Description:
        "We have a thing for printing out stuff. Every newcomer receives a kit with mug, notebook, pens, t-shirt, stickers and every thing we can think of.",
      card8Title: "Your own workstation",
      card8Description:
        'Your own desk, MacBook and monitor 27" 2k and everything you could need, we like to work well and comfortably.',
      card9Title: "The environment for your growth",
      card9Description:
        "The limits to your growth will be those which you will set. Your ideas will always be heard and considered, no matter how long you have been with us.",
    },
    openPositions: {
      title: "Open positions",
      subtitle: "Would you like to challenge yourself?",
      jobTitleMob: "Mobile developer",
      jobTitleBiz: "Business developer",
      jobTitleBra: "Brand manager",
      jobTitleDes: "Digital product designer",
      jobTitleDesJun: "Junior digital product designer",
      jobTitleBac: "Backend developer",
      jobTitle0: "Spontaneous application",
      jobDescrMob:
        "Are Apps that don't work as intended your biggest pet peeve? We think alike, we could be a great team together!",
      jobDescrBiz:
        "Are you driven by the will to grow a business and plan the further steps ahead? We are looking for you!",
      jobDescrBra:
        "Is finding new ways to communicate your bread and butter? You could be the right person to join us!",
      jobDescrBac:
        "Are you a specialist at databases and APIs? Can't stand having to wait for an app to load? This is the perfect job for you!",
      jobDescrDes:
        "If collecting colorful sticky notes is what makes you happy, you could be the person we are looking for.",
      jobDescr0:
        "We might not have the perfect open position for you, but the greatest stories come from the unexpected!",
      buttonText: "APPLY",
      yearsOfExp: "years of experience",
      noExp: "No experience required",
    },
    opinionsCards: {
      person2: "Simone, Mobile Developer",
      person1: "Beatrice, Digital Product Designer",
      person3: "Matteo, Digital Product Designer Intern",
      person4: "Tiziano, CEO",
      person5: "Eddy, CTO",
      person6: "Daniele, Backend Developer",
      person7: "Martina, Junior Frontend Developer",
      descr2:
        "Mabiloft is a constant challenge, it gives me the boost I need to give my best.",
      descr1:
        "It is a place where we can grow together, having everything we need in order to be our best",
      descr3:
        "Mabiloft provided me a great traineeship, educational, enlightening and instructive, both from a professional and, mostly, human perspective.",
      descr4:
        "Everyday I can set myself new challenges knowing that I will always find someone to work on those with",
      descr5:
        "It's like being in a catalyst, in here I can be the best version of myself.",
      descr6:
        "One must be lucky enough to work in a place like this, where your opinion matters, where you have the chance to improve yourself and be acknowledged for that.",
      descr7:
        "A place where everyone is free to show their own face and is encouraged to speak out.",
    },
  },
};

export const l_careers = (
  trad: DeepKeyof<typeof traduction[keyof typeof traduction]>
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
